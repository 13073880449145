var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "dropup float-lg-right" }, [
    _c(
      "a",
      {
        staticClass: "text-white dropdown-toggle",
        attrs: { href: "#", "data-toggle": "dropdown" },
      },
      [
        _c("i", { staticClass: "fa-solid fa-globe" }),
        _vm._v(" " + _vm._s(_vm.$t("Language")) + "\n  "),
      ]
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "dropdown-menu dropdown-menu-right mb-3" },
      [
        _c("strong", { staticClass: "dropdown-header" }, [
          _vm._v(_vm._s(_vm.$t("Select a language"))),
        ]),
        _vm._v(" "),
        _vm._l(_vm.locales, function (language, locale) {
          return _c(
            "button",
            {
              key: locale,
              staticClass: "dropdown-item",
              attrs: { type: "button" },
              on: {
                click: function ($event) {
                  return _vm.switchLocale(locale)
                },
              },
            },
            [
              _vm._v("\n      " + _vm._s(language) + "\n      "),
              locale === _vm.kadi.globals.locale
                ? _c("span", { staticClass: "float-right" }, [
                    _c("i", { staticClass: "fa-solid fa-check fa-sm" }),
                  ])
                : _vm._e(),
            ]
          )
        }),
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }