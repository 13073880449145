var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.active
    ? _c("div", [
        _c(
          "div",
          { staticClass: "alert fade show", class: `alert-${_vm.type}` },
          [
            _vm.icon
              ? _c("i", { staticClass: "fa", class: `fa-${_vm.icon}` })
              : _vm._e(),
            _vm._v(" " + _vm._s(_vm.message) + "\n  "),
          ]
        ),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }