var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.showBroadcastMsg
    ? _c("div", { staticClass: "card bg-info text-white" }, [
        _c(
          "div",
          { staticClass: "card-body" },
          [
            _c(
              "button",
              {
                staticClass: "close ml-4",
                attrs: { type: "button" },
                on: { click: _vm.dismissBroadcast },
              },
              [_c("i", { staticClass: "fa-solid fa-xmark fa-xs" })]
            ),
            _vm._v(" "),
            _c("markdown-renderer", { attrs: { input: _vm.message } }),
          ],
          1
        ),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }