var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    _vm._l(_vm.notifications, function (notification) {
      return _c(
        "div",
        { key: notification.id },
        [
          _c("notification-toast", {
            staticClass: "mb-4",
            attrs: {
              title: notification.data.title,
              body: notification.data.body,
              timestamp: notification.created_at,
              "dismiss-endpoint": notification._actions.dismiss,
            },
          }),
        ],
        1
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }