var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "a",
      {
        staticClass: "nav-link dropdown-toggle",
        attrs: { href: "#", "data-toggle": "dropdown" },
      },
      [
        _c("i", { staticClass: "fa-regular fa-lg fa-circle-question" }),
        _vm._v(" "),
        _vm.showNotification
          ? _c("span", {
              staticClass: "notification",
              attrs: { id: "notification-toggle" },
            })
          : _vm._e(),
      ]
    ),
    _vm._v(" "),
    _c("div", { staticClass: "dropdown-menu dropdown-menu-right mt-3" }, [
      _c("a", { staticClass: "dropdown-item", attrs: { href: _vm.helpUrl } }, [
        _c("i", { staticClass: "fa-regular fa-circle-question" }),
        _vm._v(" " + _vm._s(_vm.$t("Help")) + "\n    "),
      ]),
      _vm._v(" "),
      _c("a", { staticClass: "dropdown-item", attrs: { href: _vm.aboutUrl } }, [
        _c("i", { staticClass: "fa-solid fa-circle-info" }),
        _vm._v(" " + _vm._s(_vm.$t("About")) + "\n    "),
      ]),
      _vm._v(" "),
      _c(
        "a",
        {
          staticClass: "dropdown-item",
          attrs: {
            target: "_blank",
            rel: "noopener noreferrer",
            href: _vm.newsUrl,
          },
          on: { click: _vm.dismissNotification },
        },
        [
          _c("i", { staticClass: "fa-solid fa-rocket" }),
          _vm._v(" " + _vm._s(_vm.$t("What's new")) + "\n      "),
          _vm.showNotification
            ? _c("span", {
                staticClass: "notification",
                attrs: { id: "notification-item" },
              })
            : _vm._e(),
        ]
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }