var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "dropdown",
      class: { active: _vm.dropdownActive },
      attrs: { id: `dropdown-${_vm.id}` },
    },
    [
      _c(
        "div",
        {
          staticClass: "input-group input-group-sm responsive-width",
          on: { click: _vm.showDropdown },
        },
        [
          _c("div", { staticClass: "input-group-prepend" }, [
            _c(
              "button",
              {
                staticClass: "btn toggle-btn",
                attrs: { type: "button", title: _vm.$t("Toggle search mode") },
                on: {
                  click: function ($event) {
                    $event.stopPropagation()
                    return _vm.toggleSearchMode.apply(null, arguments)
                  },
                },
              },
              [
                _vm.searchByQuery
                  ? _c("i", {
                      staticClass: "fa-solid fa-magnifying-glass fa-sm",
                    })
                  : _c("i", { staticClass: "fa-solid fa-hashtag fa-sm" }),
              ]
            ),
          ]),
          _vm._v(" "),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model.trim",
                value: _vm.input,
                expression: "input",
                modifiers: { trim: true },
              },
            ],
            ref: "input",
            staticClass: "form-control custom-input",
            attrs: {
              placeholder: _vm.searchByQuery
                ? _vm.$t("Quick search")
                : _vm.$t("Find persistent ID"),
            },
            domProps: { value: _vm.input },
            on: {
              input: function ($event) {
                if ($event.target.composing) return
                _vm.input = $event.target.value.trim()
              },
              blur: function ($event) {
                return _vm.$forceUpdate()
              },
            },
          }),
          _vm._v(" "),
          _vm.input
            ? _c("div", { staticClass: "input-group-append" }, [
                _c(
                  "button",
                  {
                    staticClass: "btn btn-sm clear-btn",
                    class: { "dropdown-active": _vm.dropdownActive },
                    attrs: { type: "button" },
                    on: {
                      click: function ($event) {
                        $event.stopPropagation()
                        return _vm.clearInput.apply(null, arguments)
                      },
                    },
                  },
                  [_c("i", { staticClass: "fa-solid fa-xmark" })]
                ),
              ])
            : _vm._e(),
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "dropdown-menu responsive-width",
          class: { "d-block": _vm.dropdownActive },
        },
        [
          _vm.initialized
            ? _c(
                "div",
                [
                  _vm.items.length === 0
                    ? _c("div", { staticClass: "px-2 my-1" }, [
                        _c(
                          "strong",
                          { staticClass: "font-identifier text-muted" },
                          [_vm._v(_vm._s(_vm.$t("No results.")))]
                        ),
                      ])
                    : _vm._l(_vm.items, function (item, index) {
                        return _c("div", { key: item._links.view }, [
                          _c(
                            "a",
                            {
                              staticClass: "dropdown-item text-default p-2",
                              attrs: { href: item._links.view },
                            },
                            [
                              _c(
                                "span",
                                {
                                  staticClass:
                                    "badge badge-light border font-weight-normal float-right ml-3",
                                },
                                [_vm._v(_vm._s(item.pretty_type))]
                              ),
                              _vm._v(" "),
                              _c("div", { staticClass: "d-flow-root" }, [
                                _c(
                                  "strong",
                                  {
                                    staticClass: "font-title elevated",
                                    attrs: { title: item.title },
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm.kadi.utils.truncate(item.title, 50)
                                      )
                                    ),
                                  ]
                                ),
                              ]),
                              _vm._v(" "),
                              _c("div", { staticClass: "font-identifier" }, [
                                _vm._v("@" + _vm._s(item.identifier)),
                              ]),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticClass: "text-muted font-timestamp mt-1",
                                },
                                [
                                  _vm._v(
                                    "\n            " +
                                      _vm._s(_vm.$t("Last modified")) +
                                      " "
                                  ),
                                  _c("from-now", {
                                    attrs: { timestamp: item.last_modified },
                                  }),
                                ],
                                1
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          index < _vm.items.length - 1
                            ? _c("div", { staticClass: "dropdown-divider m-0" })
                            : _vm._e(),
                        ])
                      }),
                ],
                2
              )
            : _vm._e(),
          _vm._v(" "),
          !_vm.initialized
            ? _c("i", { staticClass: "fa-solid fa-circle-notch fa-spin p-2" })
            : _vm._e(),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }