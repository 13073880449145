var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    _vm._l(_vm.messages, function (message) {
      return _c(
        "div",
        { key: message.id },
        [
          _c("flash-message", {
            staticClass: "mb-4",
            attrs: {
              message: message.message,
              type: message.type,
              timeout: message.timeout,
            },
          }),
        ],
        1
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }