var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      ref: "toast",
      staticClass: "toast mw-100",
      attrs: {
        "data-animation": "false",
        "data-autohide": "false",
        "data-delay": "0",
      },
    },
    [
      _c("div", { staticClass: "toast-header bg-primary" }, [
        _c("span", { staticClass: "mr-auto" }, [_vm._v(_vm._s(_vm.title))]),
        _vm._v(" "),
        _c(
          "button",
          {
            staticClass: "close text-white ml-2",
            attrs: { type: "button" },
            on: { click: _vm.dismiss },
          },
          [_c("i", { staticClass: "fa-solid fa-xmark fa-xs" })]
        ),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "toast-body py-2" }, [
        _c("div", {
          staticClass: "notification-body",
          domProps: { innerHTML: _vm._s(_vm.body) },
        }),
        _vm._v(" "),
        _c("div", { staticClass: "mt-1" }, [
          _c(
            "small",
            { staticClass: "text-muted" },
            [_c("from-now", { attrs: { timestamp: _vm.timestamp } })],
            1
          ),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }